import React, { useState, useEffect } from "react";
import "./table.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Grid, Button } from "@material-ui/core";
import FormDialog from "./dialog";
import AddIcon from "@material-ui/icons/Add";
import { createPayment } from "../../context/Payments";
import dayjs, { Dayjs } from "dayjs";
import CardComponent from "../cards/card";
import Grid2 from "@mui/material/Unstable_Grid2";

const initialValue = {
  bride_name: "Sita",
  bride_details: "Daughter of Janaka of Videha",
  bride_pictureUrl:
    "https://i.pinimg.com/originals/c3/d3/f5/c3d3f564eec10ac33b74d080dbe3e08e.jpg",
  groom_name: "Rama",
  groom_details: "Son of Kaushalya and Dasharatha",
  groom_pictureUrl:
    "https://i.pinimg.com/originals/77/9a/a2/779aa206f2d860cb79e3c0b2e5dfdbe6.jpg",
  event_locationName: "Janakpurdham, Nepal",
  event_locationUrl: "https://goo.gl/maps/zwy9JYXpt9Ky6awS8",
  event_backgroundImage:
    "https://images.unsplash.com/photo-1519741497674-611481863552",
  event_startDate: new Date(),
  event_endDate: new Date(),
  event_location: { lat: 12.9236992, lng: 77.676544 },
};
function Table({ user }) {
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [events, setEvents] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initialValue);
  };
  const url = `https://f18zm0vnug.execute-api.us-east-1.amazonaws.com/events`;
  const columnDefs = [
    {
      headerName: "Invitation",
      field: "preview",
      cellRenderer: (params) => {
        console.log("params are", params, params.value);
        return `<a href=${params.value} target="_blank" rel="noopener">${params.value}</a>`;
      },
    },
    {
      headerName: "Event Time",
      field: "details.event_startDate",
      cellRenderer: (params) => {
        return dayjs(params.value).format("MMM D, YYYY h:mm A");
      },
    },
    {
      headerName: "Actions",
      field: "event_id",
      cellRendererFramework: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              handlePayment({
                ...params.data.details,
                event_id: params.data.event_id,
              })
            }
          >
            Pay
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              handleUpdate({
                ...params.data.details,
                event_id: params.data.event_id,
              })
            }
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleDelete(params.data.event_id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  // calling getUsers function for first time
  useEffect(async () => {
    getUsers();
    await getEvents();
  }, [user]);

  const getAccessToken = async () => {
    return user && user.email && (await user.getIdToken());
  };

  const getEvents = async () => {
    let events = [];
    if (user && user.email) {
      const res = await fetch(url, {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
      });
      events = await res.json();
    }
    setEvents(events);
  };

  //fetching user data from server
  const getUsers = async () => {
    console.log("getUsers", user);
    let users = [];
    if (user && user.email) {
      fetch(url, {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          setTableData(resp);
          users = resp;
        });
    } else {
      setTableData([]);
      users = [];
    }
    return users;
  };
  const onChange = (e) => {
    console.log(e);
    const { value, id } = e.target;
    console.log(e);
    setFormData({ ...formData, [id]: value });
  };
  const onGridReady = (params) => {
    setGridApi(params);
  };

  // setting update row data to form data and opening pop up window
  const handleUpdate = (oldData) => {
    setFormData(oldData);
    handleClickOpen();
  };

  // payment
  const handlePayment = (event) => {
    createPayment(event, { payment_amount: 10 }, getAccessToken);
    getUsers();
  };
  //deleting a user
  const handleDelete = async (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to delete this row",
      id
    );
    if (confirm) {
      fetch(url + `/${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
      })
        .then((resp) => resp.json())
        .then((resp) => getUsers());
    }
  };
  const handleFormSubmit = async () => {
    if (formData.event_id) {
      //updating a user
      const confirm = window.confirm(
        "Are you sure, you want to update this row ?"
      );
      confirm &&
        fetch(url + `/${formData.event_id}`, {
          method: "PUT",
          body: JSON.stringify(formData),
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${await getAccessToken()}`,
          },
        })
          .then((resp) => resp.json())
          .then((resp) => {
            handleClose();
            getUsers();
          });
    } else {
      // adding new user
      fetch(url, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          handleClose();
          getUsers();
        });
    }
  };

  const defaultColDef = {
    sortable: false,
    flex: 1,
    filter: false,
    floatingFilter: false,
  };

  function AgGridToCards(data) {
    console.log("events-", data);
    const cards = events.map((cardData, index) => (
      <CardComponent key={index} data={cardData} />
    ));
    return (
      <Grid2 container spacing={2} columns={16}>
        {cards}
      </Grid2>
    );
  }

  return (
    <div className="table">
      <Grid align="right">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <AddIcon className="rotateIcon" />
          Add New
        </Button>
      </Grid>
      <div className="ag-theme-alpine" style={{ height: "400px" }}>
        <AgGridToCards data={events} />

        <AgGridReact
          rowData={tableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
        />
      </div>
      <FormDialog
        open={open}
        handleClose={handleClose}
        data={formData}
        onChange={onChange}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default Table;
