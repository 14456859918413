const URL = "https://f18zm0vnug.execute-api.us-east-1.amazonaws.com";
const RAZOR_PAY_TEST_KEY_ID = "rzp_test_9HghhJIvw6VgJt";

export function createPayment(event, state, getAccessToken) {
  // e.preventDefault();

  const { payment_amount } = state;
  const self = this;
  const options = {
    key: RAZOR_PAY_TEST_KEY_ID,
    amount: payment_amount * 100,
    name: "Payments",
    description: "Donate yourself some time",

    async handler(response) {
      const payment_id = response.razorpay_payment_id;
      const url = URL + "/payments/capture";
      // Using my server endpoints to capture the payment
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          payment_id,
          event_id: event.event_id,
          provider: "razorpay",
        }),
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      })
        .then((resp) => resp.json())
        .then(function (data) {
          console.log("Request succeeded with JSON response", data);
          self.setState({
            refund_id: response.razorpay_payment_id,
          });
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
    },

    prefill: {
      name: "Shashank Shekhar",
      email: "example@email.com",
    },
    notes: {
      address: "Patna,India",
    },
    theme: {
      color: "#9D50BB",
    },
  };
  const rzp1 = new window.Razorpay(options);

  rzp1.open();
}
