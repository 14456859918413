import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import dayjs, { Dayjs } from "dayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MarkerSVG from "./marker.svg";

const Marker = () => (
  <>
    hello
    <img src={MarkerSVG} width={25} height={25} />
  </>
);

export default function FormDialog({
  open,
  handleClose,
  data,
  onChange,
  handleFormSubmit,
}) {
  const {
    event_id,
    bride_name,
    bride_details,
    bride_pictureUrl,
    groom_name,
    groom_details,
    groom_pictureUrl,
    event_locationName,
    event_startDate,
    event_backgroundImage,
    event_location = { lat: 12.9236992, lng: 77.676544 },
  } = data;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {event_id ? "Update event" : "Create new event"}
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              id="bride_name"
              value={bride_name}
              onChange={(e) => onChange(e)}
              placeholder="Enter Bride Name"
              label="Bride Name"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="bride_details"
              value={bride_details}
              onChange={(e) => onChange(e)}
              placeholder="Enter Bride Details"
              label="Bride Details"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="bride_pictureUrl"
              value={bride_pictureUrl}
              onChange={(e) => onChange(e)}
              placeholder="Enter Bride picture url"
              label="Bride Picture Url"
              variant="outlined"
              margin="dense"
              fullWidth
            />

            <TextField
              id="groom_name"
              value={groom_name}
              onChange={(e) => onChange(e)}
              placeholder="Enter Groom Name"
              label="Groom Name"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="groom_details"
              value={groom_details}
              onChange={(e) => onChange(e)}
              placeholder="Enter Groom Details"
              label="Groom Details"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <TextField
              id="groom_pictureUrl"
              value={groom_pictureUrl}
              onChange={(e) => onChange(e)}
              placeholder="Enter Groom picture url"
              label="Groom Picture Url"
              variant="outlined"
              margin="dense"
              fullWidth
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["MobileDateTimePicker"]}>
                <MobileDateTimePicker
                  label="Choose Event's Date & Time"
                  value={dayjs(event_startDate)}
                  onChange={(e) =>
                    onChange({
                      target: { value: e.toISOString(), id: "event_startDate" },
                    })
                  }
                  format="LLL"
                />
              </DemoContainer>
            </LocalizationProvider>

            <TextField
              id="event_locationName"
              value={event_locationName}
              onChange={(e) => onChange(e)}
              placeholder="Enter location name"
              label="Event location name"
              variant="outlined"
              margin="dense"
              fullWidth
            />

            <div style={{ height: "50vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCJmrXNYWI981srP896ywpi7J2SgV15Ofs",
                }}
                //defaultCenter={event_location}
                center={event_location}
                defaultZoom={16}
                //zoom={16}
                yesIWantToUseGoogleMapApiInternals
                onClick={(e) =>
                  onChange({
                    target: { value: e, id: "event_location" },
                  })
                }
              >
                <Marker
                  lat={event_location.lat}
                  lng={event_location.lng}
                  text="Event's Place"
                />
              </GoogleMapReact>
            </div>
            <TextField
              id="event_backgroundImage"
              value={event_backgroundImage}
              onChange={(e) => onChange(e)}
              placeholder="Enter background image url"
              label="Event background image url"
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => handleFormSubmit()}
            variant="contained"
          >
            {event_id ? "Update" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
