import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDIgzMw7Sdx0B2bODDmJi3yBwxlI8YhrGQ",
  authDomain: "test-inyt-auth.firebaseapp.com",
  projectId: "test-inyt-auth",
  storageBucket: "test-inyt-auth.appspot.com",
  messagingSenderId: "972513661853",
  appId: "1:972513661853:web:4500fcb21539e4f10d1574",
  measurementId: "G-W2M7XWTXHT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
